document.addEventListener('DOMContentLoaded', function () {
    let form = document.querySelector('.maier-form-contact');

    if (null === form) {
        return;
    }

    let watchChoice = form.querySelector('select[name$="[source][watch_brand]"]');
    let jewelChoice = form.querySelector('select[name$="[source][jewel_brand]"]');

    if (null === watchChoice || null === jewelChoice) {
        return;
    }

    let selectedRadio = form.querySelector('input[name$="[source][category]"]:checked')

    if (null === selectedRadio || selectedRadio.value !== 'watch') {
        watchChoice.setAttribute('disabled', true);
    }

    if (null === selectedRadio || selectedRadio.value !== 'jewel') {
        jewelChoice.setAttribute('disabled', true);
    }

    document.querySelectorAll('input[name$="[source][category]"]').forEach(function (radio) {
        radio.addEventListener('change', function () {
            watchChoice.setAttribute('disabled', true);
            jewelChoice.setAttribute('disabled', true);

            if (this.value === 'watch') {
                watchChoice.removeAttribute('disabled');
            }

            if (this.value === 'jewel') {
                jewelChoice.removeAttribute('disabled');
            }
        });
    });
});