import initSlickWithArrowEvent from "../components/slick-utils";

function initMaierMediaDisplayBlockSlick() {
    const slick = initSlickWithArrowEvent(
        $('.maier-media-display-block.hide-desktop .maier-media-display-block-list'),
        {
            arrows: true,
            dots: true,
            prevArrow: '<span class="slide-arrow slide-arrow-white prev">Prev</span>',
            nextArrow: '<span class="slide-arrow slide-arrow-white next">Next</span>',
            autoplay: false,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1
        }
    );
}

document.addEventListener('DOMContentLoaded', initMaierMediaDisplayBlockSlick);