import initSlickWithArrowEvent from "../../components/slick-utils";

function initShopProductSectionProductsCarousel() {
    if ($('.maier-wrapper-shop-product-section-products .maier-section-products-list').hasClass('maier-carousel-list')) {
        const slick = initSlickWithArrowEvent(
            $('.maier-wrapper-shop-product-section-products .maier-section-products-list'),
            {
                dots: false,
                dotsClass: 'slick-dots',
                prevArrow: '<span class="slide-arrow prev">Prev</span>',
                nextArrow: '<span class="slide-arrow next">Next</span>',
                autoplay: false,
                infinite: false,
                speed: 300,
                slidesToShow: 3,
                slidesToScroll: 3,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }
                    }
                ]
            }
        );
    }
}

document.addEventListener('DOMContentLoaded', initShopProductSectionProductsCarousel);

var observer = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
        if (mutation.addedNodes.length >= 1) {
            initShopProductSectionProductsCarousel();
        }
    });
});

observer.observe(document.body, { attributes: false, childList: true, characterData: false, subtree: true });