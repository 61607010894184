import initSlickWithArrowEvent from "../../components/slick-utils";

function initMaierSectionProductVariationsCarousel() {
    const slick = initSlickWithArrowEvent(
        $('.maier-wrapper-shop-product-section-products-variations .maier-section-products-list'),
        {
            dots: true,
            dotsClass: 'slick-dots',
            prevArrow: '<span class="slide-arrow prev">Prev</span>',
            nextArrow: '<span class="slide-arrow next">Next</span>',
            autoplay: false,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        }
    );
}

document.addEventListener('DOMContentLoaded', initMaierSectionProductVariationsCarousel);

var observer = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
        if (mutation.addedNodes.length >= 1) {
            initMaierSectionProductVariationsCarousel();
        }
    });
});

observer.observe(document.body, { attributes: false, childList: true, characterData: false, subtree: true });
