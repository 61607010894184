document.addEventListener('DOMContentLoaded', function () {
    let header = document.querySelector('header');
    let headerNavigation = document.querySelector('.navigation');

    if (null === headerNavigation) {
        return;
    }

    let headerNavigationPositionTop = headerNavigation.offsetTop;

    function initStickyHeader(element, elementOffsetTop) {
        let scrollOffset = 40;

        if (header.classList.contains('sticky')) {
            scrollOffset = 0;
        }

        if (window.pageYOffset > elementOffsetTop + scrollOffset) {
            element.classList.add('sticky');
        } else {
            element.classList.remove('sticky');
        }
    };

    window.addEventListener('scroll', function (event) {
        initStickyHeader(header, headerNavigationPositionTop);
    }, {passive: true});
})