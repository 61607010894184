export default class Loader {
    constructor() {
        this.element = this.get();
    }
    get() {
        return document.querySelector('.loader-container') ?? this.init();
    }
    init () {
        let div = document.createElement('div');
        div.innerHTML = '<div class="loader-container"><div class="loader"></div></div>';

        let loader = div.firstChild;

        document.querySelector('body').append(loader);

        return loader;
    }
    toggle() {
        this.get().classList.toggle('visible');
    }
    show() {
        this.get().classList.add('visible');
    }
    hide() {
        this.get().classList.remove('visible');
    }
}