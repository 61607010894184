function initEnabledFilters(formSelector) {
    let $enabledFilters = $('.enabled-filters ul');

    if (0 !== $(document).find(formSelector).find('.filter-value-list input:checked').length) {
        $enabledFilters.empty();
    }

    $(document).find(formSelector).find('.filter-value-list input:checked').each(function (index) {
        let $label = $('label[for="' + $(this).attr('id') + '"]');
        let $li = $('<li class="reset-filter-item"></li>');
        let name = $(this).closest('.filter-value-list').siblings('label').text();
        let value = $label.text();

        $li.attr('data-related-checkbox', $(this).attr('id')).on('click', function (event) {
            $('#'+$(this).attr('data-related-checkbox')).prop('checked', false);
            $(this).remove();
            $(document).find(formSelector).submit();
        });

        $('#'+$li.attr('data-related-checkbox')).on('click', function (event) {
            $li.remove();
        });

        $enabledFilters.append($li.text(name+': '+value));
    });
}

document.addEventListener('DOMContentLoaded', function(event) {
    let formSelector = 'form[name="product_filter_form"]';
    let $form = $(document).find(formSelector);

    if (null === $form || 0 === $form.length) {
        return;
    }

    let FilterAjax = new window.AjaxFilter(formSelector, 'product_search_form', function(content) {
        let paginationContainer = '.pagination-container';
        let productListSelector = '.product-list';

        $(document).find(formSelector).replaceWith($(content).find(formSelector)[0]);
        $(document).find(paginationContainer).replaceWith($(content).find(paginationContainer)[0])
        $(document).find(productListSelector).replaceWith($(content).find(productListSelector)[0])

        initEnabledFilters(formSelector);
        $('html, body').animate({
            'scrollTop': $('#filter-form-top').offset().top - 68
        }, 1);
    });

    initEnabledFilters(formSelector);
});