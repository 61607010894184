document.addEventListener('DOMContentLoaded', function () {
    if (null === document.querySelector('[data-widget-avis-verifies]')) {
        return;
    }

    let script = document.createElement('script');
    script.src = "//cl.avis-verifies.com/fr/widget4/widget04.min.js";
    document.body.appendChild(script);

    script.addEventListener('load', function() {
        fetch(document.querySelector('[data-widget-avis-verifies]').getAttribute('data-widget-avis-verifies'), {
            type: 'GET',
            dataType: 'html'
        }).then(function (response, status) {
            return response.text();
        }).then(function (response) {
            var elements = response.split(";");
            var reviewDate = new Date();
            reviewDate.setMonth(reviewDate.getMonth() - 12)

            $('.data-rating-count').html(elements[0]);
            $('.data-rating-date').html(reviewDate.toLocaleDateString('fr-FR', { year: '2-digit', month: 'numeric', day: 'numeric' }));
        });
    })
})