import publicIp from 'public-ip';
import getFingerprint from './fingerprint';

function initFingerprint() {
    document.querySelectorAll('form > input[name="fingerprint"]').forEach(function (input) {
        getFingerprint({
            hash: true
        }).then(function (fingerprint) {
            input.value = fingerprint;
        })
    });
}

function initIP() {
    document.querySelectorAll('form > input[name="ip"]').forEach(function (input) {
        publicIp.v4().then(function (ip) {
            input.value = ip;
        })
    });
}

document.addEventListener('DOMContentLoaded', function () {
    initFingerprint();
    initIP();
})