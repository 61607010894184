document.addEventListener('DOMContentLoaded', function () {
    $('.maier-footer-informations-reviews-list').on('init', function (event, slick) {
        slick.$prevArrow.css('visibility', 'hidden');
    })

    $('.maier-footer-informations-reviews-list').not('.slick-initialized').slick({
        dots: false,
        arrows: true,
        prevArrow: '<span class="slide-arrow prev">Prev</span>',
        nextArrow: '<span class="slide-arrow next">Next</span>',
        autoplay: false,
        infinite: false,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.maier-footer-informations-reviews-list').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        slick.$prevArrow.css('visibility', 0 === nextSlide ? 'hidden' : 'visible')
        slick.$nextArrow.css('visibility', 0 >= (slick.slideCount - (nextSlide + 2)) ? 'hidden' : 'visible')
    });
});