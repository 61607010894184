document.addEventListener("DOMContentLoaded", function() {
    let rolexRetailerClocks = document.querySelectorAll('.rolex-retailer-clock');

    rolexRetailerClocks.forEach(function (rolexRetailerClock) {
        let script = document.createElement('script');
        script.src = "https://static.rolex.com/retailers/clock/retailercall.js"
        document.body.appendChild(script);

        script.addEventListener('load', function() {
            var rdp = new RolexRetailerClock();
            var rdpConfig = {
                dealerAPIKey: rolexRetailerClock.getAttribute('data-dealer-api-key'),
                lang: rolexRetailerClock.getAttribute('data-lang'),
                colour: 'gold'
            }
            try {
                rdp.getRetailerClock(rdpConfig);
            } catch (err) {}
        });
    });
});