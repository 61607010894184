import initSlickWithArrowEvent from "../../components/slick-utils";

function initShopProductMediaDisplayBlockCarousel() {
    const slick = initSlickWithArrowEvent(
        $('.maier-wrapper-shop-product-media-display-block.hide-desktop .maier-media-display-block-list'),
        {
            arrows: true,
            dots: false,
            dotsClass: 'slick-dots',
            prevArrow: '<span class="slide-arrow prev">Prev</span>',
            nextArrow: '<span class="slide-arrow next">Next</span>',
            autoplay: false,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1
        }
    );
}

document.addEventListener('DOMContentLoaded', initShopProductMediaDisplayBlockCarousel);

var observer = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
        if (mutation.addedNodes.length >= 1) {
            initShopProductMediaDisplayBlockCarousel();
        }
    });
});

observer.observe(document.body, { attributes: false, childList: true, characterData: false, subtree: true });