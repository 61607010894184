import initSlickWithArrowEvent from "../components/slick-utils";

function initMaierCarouselPresentationSlick() {
    let slick = initSlickWithArrowEvent(
        $('.maier-carousel-presentation-list'),
        {
            dots: false,
            arrows: true,
            prevArrow: '<span class="slide-arrow slide-arrow-white prev">Prev</span>',
            nextArrow: '<span class="slide-arrow slide-arrow-white next">Next</span>',
            autoplay: true,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        arrows: false,
                        autoplay: false,
                        dots: true,
                        dotsClass: 'maier-carousel-presentation-dots'
                    }
                }
            ]
        }
    );

    slick.on('afterChange', function(event, slick, currentSlide) {
        var activeDotEl = $(event.target).find('.maier-carousel-presentation-dots').find('li.slick-active');
        if (activeDotEl.get(0)) {
            activeDotEl.get(0).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        }
    });
}

document.addEventListener('DOMContentLoaded', initMaierCarouselPresentationSlick);