import Loader from "./Loader";

export default class AjaxLink {
    constructor({ selector = null, targetElements = null, isTriggerReplace = true, callback = function() {} }) {
        this.selector = selector;
        this.targetElements = targetElements;
        this.isTriggerReplace = isTriggerReplace; // What is the aim of this attribute ?
        this.callback = callback;
        this.elements = document.querySelectorAll(this.selector);
        this.loader = new Loader();

        if (0 !== this.elements.length) {
            this.init();
        }
    }
    init() {
        this.elements.forEach((link) => {
            if (link.classList.contains('ajax-link-initialized')) {
                return;
            }

            link.addEventListener('click', (e) => {
                e.preventDefault();

                this.loader.show();

                let headers = (new Headers())
                    .append('X-Requested-With', 'XMLHTTPRequest')
                ;

                fetch(link.href, {
                    credentials: 'same-origin',
                    headers: headers
                }).then((response) => {
                    return response.text();
                }).then((html) => {
                    let div = document.createElement('div');
                    div.innerHTML = html;

                    if (this.targetElements !== null) {
                        Object.entries(this.targetElements).forEach(element => {
                            try {
                                let container = document.querySelector(element[0]);
                                let result = div.querySelector(element[1]);
                                container.innerHTML = result.innerHTML;

                                let firstScript = null;
                                let previousScript = null

                                result.querySelectorAll('script').forEach(function (resultScript) {
                                    let script = document.createElement('script');

                                    if (resultScript.src !== '') {
                                        script.src = resultScript.src;
                                    }

                                    script.innerHTML = resultScript.innerHTML;

                                    if (firstScript === null) {
                                        firstScript = script;
                                    }

                                    if (previousScript !== null && previousScript.src !== '') {
                                        previousScript.onload = function () {
                                            container.appendChild(script);
                                        }
                                    } else {
                                        container.appendChild(script);
                                    }

                                    if (script.src !== '') {
                                        previousScript = script;
                                    }
                                })

                                if (null !== firstScript && firstScript.src !== '') {
                                    container.appendChild(firstScript);
                                }
                            } catch {
                                console.error(element[1].concat(' is not defined'));
                            }
                        });
                    }

                    this.loader.hide();

                    if(this.isTriggerReplace) {
                        new AjaxLink({
                            selector: this.selector,
                            targetElements: this.targetElements,
                            isTriggerReplace: this.isTriggerReplace,
                            callback: this.callback,
                        });
                    }
                }).then(() => {
                    this.callback(link);
                })
                ;
            });

            link.classList.add('ajax-link-initialized');
        });
    }
}
