import Loader from './Loader';

export default class AjaxSelect {
    constructor({ selector = null, targetElements = null, isTriggerReplace = true, action = null, enabled = true, callback = function () { }, onError = function () { }, onSubmit = function () { } }) {
        this.selector = selector;
        this.targetElements = targetElements;
        this.isTriggerReplace = isTriggerReplace;
        this.action = action;
        this.callback = callback;
        this.onSubmit = onSubmit;
        this.onError = onError;
        this.enabled = enabled;
        this.elements = document.querySelectorAll(this.selector);
        this.loader = new Loader();

        if (0 !== this.elements.length) {
            this.init();
        }
    }
    enable() {
        this.enabled = true;
    }
    disable() {
        this.enabled = false;
    }
    init() {
        this.elements.forEach((select) => {
            select.addEventListener('change', (e) => {
                e.preventDefault();

                this.onSubmit.call(this);

                if (!this.enabled) {
                    return;
                }

                this.loader.show();

                let headers = new Headers();
                headers.append('X-Requested-With', 'XMLHttpRequest');
                fetch(select.value, {
                    credentials: 'same-origin',
                    headers: headers
                }).then((response) => {
                    return response.text();
                }).then((html) => {
                    let div = document.createElement('div');
                    div.innerHTML = html;

                    if (this.targetElements.length !== null) {
                        Object.entries(this.targetElements).forEach((element) => {
                            try {
                                let container = document.querySelector(element[0]);
                                let result = div.querySelector(element[1]);
                                container.innerHTML = result.innerHTML;

                                let firstScript = null;
                                let previousScript = null
                                result.querySelectorAll('script').forEach(function (resultScript) {
                                    let script = document.createElement('script');

                                    if (resultScript.src !== '') {
                                        script.src = resultScript.src;
                                    }

                                    script.innerHTML = resultScript.innerHTML;

                                    if (firstScript === null) {
                                        firstScript = script;
                                    }

                                    if (previousScript !== null && previousScript.src !== '') {
                                        previousScript.onload = function () {
                                            container.appendChild(script);
                                        }
                                    } else {
                                        container.appendChild(script);
                                    }

                                    if (script.src !== '') {
                                        previousScript = script;
                                    }
                                })

                                if (null !== firstScript && firstScript.src !== '') {
                                    container.appendChild(firstScript);
                                }
                            } catch (e) {
                                console.error(element[1].concat(' is not defined'));

                                throw e;
                            }
                        });
                    }

                    this.loader.hide();

                    if (this.isTriggerReplace) {
                        new AjaxSelect({
                            selector: this.selector,
                            targetElements: this.targetElements,
                            isTriggerReplace: this.isTriggerReplace,
                            action: this.action,
                            callback: this.callback,
                        });
                    }

                    this.callback.call(this, select);
                }).catch(() => {
                    this.loader.hide();

                    this.onError.call(this, select);
                });
            });
        });
    }
}
