import $ from 'jquery';

export default class Search {
    constructor(autocompleteId, searchFormId, callback) {
        this.searchFormId = searchFormId;
        this.$autocomplete = $('#' + autocompleteId);
        this.$searchInput = $('#' + this.searchFormId + '_search_query');
        this.$searchForm = this.$searchInput.closest("form");
        this.callback = callback;

        this.init();
    }

    init() {
        let timeout = null;

        this.$searchInput.on('keyup', event => {
            clearTimeout(timeout);

            timeout = setTimeout(event => {
                this.getSearchResult();
            }, 500);
        });

        let autocomplete = this.$autocomplete.get(0);
        let input = this.$searchInput.get(0);

        const outsideClickListener = event => {
            if (autocomplete.classList.contains('open')) {
                autocomplete.classList.remove('open');
            }
        }

        if (null !== autocomplete) {
            document.addEventListener('click', outsideClickListener);
            input.addEventListener('input', event => {
                let value = input.value.trim();

                if (!value) {
                    outsideClickListener(event);
                }
            });
        }
    }

    getSearchResult() {
        if (this.formIsValid()) {
            $.ajax({
                method: this.$searchForm.attr('method'),
                url: '/search.json',
                data: this.$searchForm.serializeArray(),
                beforeSend: function (jqXHR, settings) {
                    jqXHR.url = settings.url;
                }
            }).done((content, textStatus, jqXHR) => {
                this.callback(content, this.$searchInput.val());
            }).fail(xhr => {
                console.error('An error occured', xhr);
            });
        }
    }

    formIsValid() {
        for (let element of this.$searchForm.serializeArray()) {
            if (!element.value || element.value === '') {
                return false;
            }
        }

        return true;
    }
}