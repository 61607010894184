function buildShopProductElement(product) {
    let $li = $(
        '<li>' +
            '<a>' +
                '<img class="product-image" src="" />' +
                '<span>' +
                    '<span class="product-name"></span>' +
                    '<span class="product-brand"></span>' +
                    '<span class="product-price"></span>' +
                '</span>' +
            '</a>' +
        '</li>'
    );

    if (product.media.length > 0) {
        $li.find(".product-image").attr("src", product.media[0].url);
    }

    $li.find("a").attr("href", product.websiteUrl);
    $li.find(".product-name").text(product.name.toUpperCase());
    $li.find(".product-brand").text(product.brand.name.toUpperCase());
    $li.find(".product-price").text(
        0 === Math.round(product.finalPrice)
            ? document.querySelector('#product_search_form_autocomplete').getAttribute('data-price-label')
            : Math.round(product.finalPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' €'
    );

    return $li;
}

function buildBrandElement(element) {
    let $li = $(
        '<li>' +
            '<a><span class="brand-name"></span></a>' +
            '<span class="product-total-count"></span>' +
        '</li>'
    );

    $li.find("a").attr("href", element.shopProductBrand.productBrandUrl);
    $li.find(".brand-name").text(element.shopProductBrand.productBrand.name);
    $li.find(".product-total-count").text("(" + element.total_count + ")");

    return $li;
}

function getProductAutocompleteList($element)
{
    if ($('#' + $element.attr('id') + '_products').length === 0) {
        $productElement = $(
            '<div id="'+ $element.attr('id') + '_products' + '" class="product-search-form-results">' +
                '<span>' + $element.attr('data-products-label') + '</span>' +
                '<ul></ul>' +
            '</div>'
        );

        $productElement.prependTo($element);
    }

    return $('#' + $element.attr('id') + '_products');
}

document.addEventListener("DOMContentLoaded", function(event) {
    if (null === document.querySelector('#product_search_form_autocomplete')) {
        return;
    }

    let $autocompleteContainer = $("#product_search_form_autocomplete");
    let $productList = getProductAutocompleteList($autocompleteContainer);

    let Search = new window.Search('product_search_form_autocomplete', document.querySelector('#product_search_form_autocomplete').getAttribute('data-product-search-form-id'), function(result, search) {
        $productList.find('ul').html('');

        result.autocomplete.products.forEach(function(product) {
            $productList.find('ul').append(buildShopProductElement(product));
        });

        $autocompleteContainer.addClass("open");
    });
});

document.addEventListener("DOMContentLoaded", function(event) {
    if (null === document.querySelector('#product_search_form_mobile_autocomplete')) {
        return;
    }

    let $autocompleteContainer = $("#product_search_form_mobile_autocomplete");
    let $productList = getProductAutocompleteList($autocompleteContainer);

    let Search = new window.Search('product_search_form_mobile_autocomplete', document.querySelector('#product_search_form_mobile_autocomplete').getAttribute('data-product-search-form-id'), function(result, search) {
        $productList.find('ul').html('');

        result.autocomplete.products.forEach(function(product) {
            $productList.find('ul').append(buildShopProductElement(product));
        });

        $autocompleteContainer.addClass("open");
    });
});