function initMaierProductVariationsModal() {
    if (null === document.querySelector('.maier-product-variation-link')) {
        return;
    }

    let modal = new Modal({
        'id': 'maier-modal-product-variations',
        'create': true,
    });

    new AjaxLink({
        selector: '.maier-product-variation-link',
        targetElements: {
            '#maier-modal-product-variations > .modal-content .content' : 'main > .content'
        },
        isTriggerReplace: false,
        callback: function() {
            modal.show();
        }
    });
}

document.addEventListener('DOMContentLoaded', initMaierProductVariationsModal);

var observer = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
        if (mutation.addedNodes.length >= 1) {
            initMaierProductVariationsModal();
        }
    });
});

observer.observe(document.body, { attributes: false, childList: true, characterData: false, subtree: true });
