function play(video, playButton) {
    if(video.paused){
        video.play();
        playButton.classList.add("hide");
    } else {
        video.pause();
        playButton.classList.remove("hide");
    }
}

document.addEventListener('DOMContentLoaded', function () {
    let playButton = document.querySelector('.maier-video .maier-video-play-button');

    let video = document.querySelector('.maier-video video');
    if (video) {

        if (null === video || null === playButton) {
            return;
        }

        video.addEventListener('click', function(){ play(video, playButton); }, {passive: true});
        playButton.addEventListener('click', function(){ play(video, playButton); }, {passive: true});
    }


    let iframe = document.querySelector('.maier-video iframe');
    if (iframe) {
        playButton.classList.add("hide");
    }
});