document.addEventListener('DOMContentLoaded', function () {
    if (null === document.querySelector(".sub-navigation")) {
        return;
    }

    var elements = document.querySelectorAll("[class^='sub-navigation-item-']");
    var activeElement = document.querySelector("[class^='sub-navigation-item-'].active");


    elements.forEach(element => {
        element.addEventListener("mouseover", function() {
            if (null !== activeElement) {
                activeElement.classList.remove('active');
            }

            this.classList.add('active');
            activeElement = this;
        }, {passive: true})
    })
})