import EnhancedEcommerce from './EnhancedEcommerce';
import EnhancedEcommerceProduct from './EnhancedEcommerceProduct';
import EnhancedEcommercePromotion from './EnhancedEcommercePromotion';

let enhancedEcommerce = new EnhancedEcommerce();

window.initEnhancedElements = function initEnhancedElements(baseElement) {
    enhancedEcommerce.clear();

    // view_promotion + select_promotion
    let enhancedEcommercePromotionImpressions = [];
    baseElement.querySelectorAll('[data-promotion]').forEach(function (element, index) {
        let promotion = JSON.parse(element.getAttribute('data-promotion'));
        let enhancedEcommercePromotion = new EnhancedEcommercePromotion({
            promotion_id: promotion.id,
            promotion_name: promotion.name,
            location_id: promotion.location_id
        });

        element.addEventListener('click', function (event) {
            enhancedEcommerce.selectPromotion(enhancedEcommercePromotion);
        }, {passive: true});

        enhancedEcommercePromotionImpressions.push(enhancedEcommercePromotion);
    });

    enhancedEcommerce.viewPromotions(enhancedEcommercePromotionImpressions);

    // View_item_list + select_item
    let enhancedEcommerceProductImpressions = [];
    baseElement.querySelectorAll('[data-product]').forEach(function (element, index) {
        let product = JSON.parse(element.getAttribute('data-product'));
        let enhancedEcommerceProduct = new EnhancedEcommerceProduct({
            item_id: product.id,
            item_name: product.name,
            item_brand: product.brand,
            item_categories: product.categories,
            item_list_name: element.hasAttribute('data-list-name') ? element.getAttribute('data-list-name') : 'N/A',
            price: product.price,
            index: ++index
        });

        element.addEventListener('click', function (event) {
            enhancedEcommerce.selectItem(enhancedEcommerceProduct);
        }, {passive: true});

        enhancedEcommerceProductImpressions.push(enhancedEcommerceProduct);
    });

    enhancedEcommerce.viewItemList(enhancedEcommerceProductImpressions);

    // view_item ([data-product] + view)
    baseElement.querySelectorAll('[data-product-card]').forEach(function (element) {
        let product = JSON.parse(element.getAttribute('data-product-card'));
        let enhancedEcommerceProduct = new EnhancedEcommerceProduct({
            item_id: product.id,
            item_name: product.name,
            item_brand: product.brand,
            item_categories: product.categories,
            price: product.price,
        });

        enhancedEcommerce.viewItem(enhancedEcommerceProduct);
    });

    // add_to_cart
    baseElement.querySelectorAll('#shopping-cart-add-product-form').forEach(function (element) {
        let product = JSON.parse(element.getAttribute('data-product'));
        let enhancedEcommerceProduct = new EnhancedEcommerceProduct({
            item_id: product.id,
            item_name: product.name,
            item_brand: product.brand,
            item_categories: product.categories,
            item_variant: product.variant,
            price: product.price,
            quantity: 1,
        });

        element.addEventListener('submit', function (event) {
            enhancedEcommerce.addToCart(enhancedEcommerceProduct);
        });
    });

    // remove_from_cart
    baseElement.querySelectorAll('#shopping-cart-remove-product-form').forEach(function (element) {
        let product = JSON.parse(element.getAttribute('data-product'));
        let enhancedEcommerceProduct = new EnhancedEcommerceProduct({
            item_id: product.id,
            item_name: product.name,
            item_brand: product.brand,
            item_categories: product.categories,
            item_variant: product.variant,
            price: product.price,
            quantity: 1,
        });


        element.addEventListener('submit', function (event) {
            event.preventDefault();
            enhancedEcommerce.removeFromCart(enhancedEcommerceProduct);
        });
    });

    baseElement.querySelectorAll('#shopping-cart-remove-all-product-form').forEach(function (element) {
        let product = JSON.parse(element.getAttribute('data-product'));
        let enhancedEcommerceProduct = new EnhancedEcommerceProduct({
            item_id: product.id,
            item_name: product.name,
            item_brand: product.brand,
            item_categories: product.categories,
            item_variant: product.variant,
            price: product.price,
            quantity: product.quantity
        });

        element.addEventListener('submit', function (event) {
            event.preventDefault();
            enhancedEcommerce.removeFromCart(enhancedEcommerceProduct);
        });
    });

    baseElement.querySelectorAll('#emptying-cart-form').forEach(function (element) {
        let products = JSON.parse(element.getAttribute('data-products'));

        element.addEventListener('submit', function (event) {
            event.preventDefault();
            for (let product of products) {
                enhancedEcommerce.removeFromCart(
                    new EnhancedEcommerceProduct({
                        item_id: product.id,
                        item_name: product.name,
                        item_brand: product.brand,
                        item_categories: product.categories,
                        item_variant: product.variant,
                        price: product.price,
                        quantity: product.quantity
                    })
                );
            }
        });
    });

    //  view cart
    baseElement.querySelectorAll('table.cart[data-products]').forEach(function (element) {
        let products = JSON.parse(element.getAttribute('data-products'));

        let enhancedEcommerceProductCartImpressions = [];
        for (let product of products) {
            enhancedEcommerceProductCartImpressions.push(
                new EnhancedEcommerceProduct({
                    item_id: product.id,
                    item_name: product.name,
                    item_brand: product.brand,
                    item_categories: product.categories,
                    item_variant: product.variant,
                    price: product.price,
                    quantity: product.quantity
                })
            );

        }

        enhancedEcommerce.viewCart(enhancedEcommerceProductCartImpressions);
    });
}

document.addEventListener('DOMContentLoaded', function () {
    initEnhancedElements(document);

    var observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
            if (mutation.addedNodes.length >= 1) {
                for (var i = 0; i < mutation.addedNodes.length; i++) {
                    if (undefined !== mutation.addedNodes[i].localName) {
                        initEnhancedElements(mutation.addedNodes[i]);
                    }
                }
            }
        });
    });

    observer.observe(document.body, { attributes: false, childList: true, characterData: false, subtree: true });
});
