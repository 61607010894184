function updateSelectedField(phoneNumberInput, displayedSelectedField) {
    let selectedField = phoneNumberInput.options[phoneNumberInput.selectedIndex];
    selectedField.selected = false;
    displayedSelectedField.text = selectedField.text.match(/\+[0-9]+/);
    displayedSelectedField.value = selectedField.value;
    displayedSelectedField.selected = true;
}

function initFormPhoneNumber(phoneNumberInput) {
    let displayedSelectedField = document.createElement('option');
    displayedSelectedField.setAttribute('hidden', true);

    updateSelectedField(phoneNumberInput, displayedSelectedField);

    phoneNumberInput.appendChild(displayedSelectedField);

    phoneNumberInput.addEventListener('change', function () {
        updateSelectedField(phoneNumberInput, displayedSelectedField)
    });
}

document.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('.maier-input-phone-number select').forEach(function (phoneNumberInput) {
        initFormPhoneNumber(phoneNumberInput);
    });
});

var observer = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
        if (mutation.addedNodes.length >= 1) {
            for (var i = 0; i < mutation.addedNodes.length; i++) {
                if (undefined !== mutation.addedNodes[i].localName) {
                    mutation.addedNodes[i].querySelectorAll('.maier-input-phone-number select').forEach(function (phoneNumberInput) {
                        initFormPhoneNumber(phoneNumberInput);
                    });
                }
            }
        }
    });
});

observer.observe(document.body, { attributes: false, childList: true, characterData: false, subtree: true });