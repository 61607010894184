document.addEventListener('DOMContentLoaded', function () {
    function initProductQuantityInputEvents() {
        document.querySelectorAll('.maier-customer-cart-products-list-item-content-information-actions-quantity-input').forEach(function (input) {
            input.addEventListener('focus', function () {
                let initialProductQuantity = input.value;
                input.addEventListener('blur', function () {
                    let finalProductQuantity = input.value;

                    let productQuantityDifference = finalProductQuantity - initialProductQuantity;

                    if (productQuantityDifference === 0) {
                        return;
                    }

                    let removeProductForm = input.parentNode.querySelector('#shopping-cart-remove-product-form');

                    removeProductForm.querySelector('[name="quantity"]').value = -productQuantityDifference;
                    removeProductForm.querySelector('button[type="submit"]').click();
                }, { once: true })
            })
        });
    }

    if (null !== document.querySelector('.maier-customer-cart')) {
        new AjaxForm({
            selector: '.ajax-form',
            targetElements: {
                '.actions > .shopping-cart': '.actions > .shopping-cart',
                '.maier-customer-container': '.maier-customer-container'
            },
            callback: function() {
                initProductQuantityInputEvents();
            }
        });

        initProductQuantityInputEvents();
    }
})
