import { Loader } from '@googlemaps/js-api-loader';

document.addEventListener('DOMContentLoaded', function () {
    let mapElement = document.querySelector('.maier-wrapper-google-section-map');

    if (null === mapElement) {
        return;
    }

    const loader = new Loader({
        apiKey: mapElement.getAttribute('data-google-map-api-key'),
        version: "weekly",
        libraries: ["places"]
    });

    loader
        .load()
        .then((google) => {
            let mapElementIframe = mapElement.querySelector('iframe');

            let map = new google.maps.Map(mapElement, {
                center: {
                    lat: parseFloat(mapElement.getAttribute('data-latitude')),
                    lng: parseFloat(mapElement.getAttribute('data-longitude'))
                },
                zoom: parseInt(mapElement.getAttribute('data-zoom'))
            });

            let infowindow = new google.maps.InfoWindow();

            let markers = JSON.parse(mapElement.getAttribute('data-markers'));
            for (let marker of markers) {
                let googleMarker = new google.maps.Marker({
                    title: marker.title,
                    position: {
                        lat: parseFloat(marker.latitude),
                        lng: parseFloat(marker.longitude)
                    },
                    map: map
                });

                google.maps.event.addListener(googleMarker, 'click', function (event) {
                    infowindow.setContent(this.get('title'));
                    infowindow.open(map,this);
                });
            }

            mapElement.style.width = mapElementIframe.width;
            mapElement.style.height = mapElementIframe.height;
        })
    ;
})
