// Sass
require('../sass/app.scss');

// FilterAjax
import FilterAjax from './src/FilterAjax';
window.FilterAjax = FilterAjax;

// Search
import Search from './src/Search';
window.Search = Search;
