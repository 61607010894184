import initSlickWithArrowEvent from "../../components/slick-utils";

function initShopProductBrandCarouselSlick() {
    const slick = initSlickWithArrowEvent(
        $('.maier-wrapper-shop-product-brand-carousel .maier-carousel-list'),
        {
            dots: false,
            dotsClass: 'slick-dots',
            prevArrow: '<span class="slide-arrow prev">Prev</span>',
            nextArrow: '<span class="slide-arrow next">Next</span>',
            autoplay: false,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        rows: 2,
                        slidesPerRow: 2
                    }
                }
            ]
        }
    );
}

document.addEventListener('DOMContentLoaded', initShopProductBrandCarouselSlick);

var observer = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
        if (mutation.addedNodes.length >= 1) {
            initShopProductBrandCarouselSlick();
        }
    });
});

observer.observe(document.body, { attributes: false, childList: true, characterData: false, subtree: true });
