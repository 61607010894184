document.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('.maier-modal-popup').forEach(function (popup) {
        let modal = new Modal({
            id: popup.id,
            maximumDisplayTimes: popup.getAttribute('data-maximum-display-times'),
            excludedAutoCloseSelectors: ['#onetrust-consent-sdk']
        });

        modal.show();
    })
});