export default class Toast {
    createToastElement(message, time, _class) {
        let toast = document.createElement('div');
        toast.classList.add('maier-toast');
        toast.classList.add(_class);
        toast.innerHTML = message;

        setTimeout(() => {
            toast.remove();
        }, time);

        return toast;
    }
    success(message, time = 3000) {
        document.querySelector('body').append(this.createToastElement(message, time, 'maier-toast-success'));
    }
    error(message, time = 3000) {
        document.querySelector('body').append(this.createToastElement(message, time, 'maier-toast-error'));
    }
    warning(message, time = 3000) {
        document.querySelector('body').append(this.createToastElement(message, time, 'maier-toast-warning'));
    }
    info(message, time = 3000) {
        document.querySelector('body').append(this.createToastElement(message, time, 'maier-toast-info'));
    }
}
