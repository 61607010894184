document.addEventListener('DOMContentLoaded', function () {
    let scrollToTopButton = document.querySelector(".maier-button-scroll-to-top");

    if (null === scrollToTopButton) {
        return;
    }

    scrollToTopButton.addEventListener('click', function () {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    });

    window.addEventListener('scroll', function () {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            scrollToTopButton.classList.add('visible');
        } else {
            scrollToTopButton.classList.remove('visible');
        }
    }, {passive: true});
});