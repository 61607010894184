document.querySelectorAll('input[type="file"]').forEach(function(input) {
    let label = input.nextElementSibling;

    input.addEventListener('change', function(e) {
        let fileName = this.files && this.files.length > 1 ?
            ('{count} fichiers sélectionnés').replace('{count}', this.files.length):
            e.target.value.split('\\').pop()
        ;

        label.innerHTML = fileName ? fileName : defaultContent;
    });
});
