
import $ from 'jquery';
import queryString from 'query-string';

export default class FilterAjax {
    constructor(formSelector, searchFormName, callback) {
        this.formSelector = formSelector;
        this.searchFormName = searchFormName+'[search_query]';
        this.callback = callback;

        this.initFilter();
    }

    initFilter() {
        this.$formFilter = $(this.formSelector);

        let url = location.protocol + '//' + location.host + location.pathname;

        this.$formFilter.on('change', '.filter-value-list input', e => {
            e.preventDefault();

            this.$formFilter.submit();
        });

        this.$formFilter.on('change', 'select', e => {
            e.preventDefault();

            this.$formFilter.submit();
        });

        this.$formFilter.on('submit', e => {
            e.preventDefault();

            if (undefined != this.$formFilter.attr('action')) {
                url = this.$formFilter.attr('action');
            }

            this.sendRequest(this.$formFilter.attr('method'), url, this.$formFilter.serializeArray())
        });

        $('.pagination li a').on('click', e => {
            e.preventDefault();

            this.sendRequest('GET', $(e.target).attr('href'));
        });

        $('.filter-reset-button').on('click', e => {
            this.sendRequest('GET', url, {})
        });
    }

    sendRequest(method, url, data = []) {
        let $loader = $('<div class="loader-container"><div class="loader"></div></div>');
        $('body').append($loader);

        const parameters = queryString.parse(location.search, {arrayFormat: 'index', decode: true});
        if (undefined != parameters[this.searchFormName]) {
            data.push({
                name: this.searchFormName,
                value: parameters[this.searchFormName]
            });
        }

        var xhr = new XMLHttpRequest();

        $.ajax({
            method: method,
            url: url,
            data: data,
            xhr: function() {
                return xhr;
            },
            success: function () {
                history.replaceState({}, '', xhr.responseURL);
            }
        }).done((content, textStatus, jqXHR) => {
            this.callback(content);

            $loader.remove();
            this.initFilter();
        }).fail(xhr => {
            console.error('An error occured', xhr);
        });
    }
}
