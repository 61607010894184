// Scripts
import Modal from './Modal';
import ClipboardCopyButton from './ClipboardCopyButton';
import AjaxFilter from './AjaxFilter';
import AjaxForm from './AjaxForm';
import AjaxLink from './AjaxLink';
import AjaxSelect from './AjaxSelect';
import EnhancedEcommerce from './EnhancedEcommerce';
import EnhancedEcommerceProduct from './EnhancedEcommerceProduct';
import Toast from './Toast';
import Loader from './Loader';

window.Modal = Modal;
window.ClipboardCopyButton = ClipboardCopyButton;
window.AjaxFilter = AjaxFilter;
window.AjaxForm = AjaxForm;
window.AjaxLink = AjaxLink;
window.AjaxSelect = AjaxSelect;
window.EnhancedEcommerce = EnhancedEcommerce;
window.EnhancedEcommerceProduct = EnhancedEcommerceProduct;
window.Toast = new Toast();

document.addEventListener('DOMContentLoaded', function () {
    window.Loader = new Loader();
});

require('./components/all');
require('./widgets/all');
require('./wrappers/all');

require('./dynamic-script-load');
require('./enhanced-ecommerce');
require('./maier-header');
require('./menu-display');
require('./netreviews-slick');
require('./product-search');
require('./retailer-clock');
require('./widget-avis-verifies');