document.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('.maier-tabs').forEach(function (tabs) {
        tabs.querySelectorAll('.maier-tab-item').forEach(function (tab) {
            let label = tab.querySelector('label');
            let input = tabs.parentNode.querySelector('input#' + label .getAttribute('for'));

            input.addEventListener('change', function () {
                tabs.querySelectorAll('.maier-tab-item').forEach(function (_tab) {
                    _tab.classList.remove('maier-tab-item-active');
                })

                tab.classList.add('maier-tab-item-active');

                $('.slick-slider').slick('refresh');
            });
        });
    });
});