export default class EnhancedEcommercePromotion {
    constructor({promotion_id = null, promotion_name = null, location_id = null}) {
        this.promotion_id = promotion_id;
        this.promotion_name = promotion_name;
        this.location_id = location_id;
    }

    getPromotionId() {
        return this.promotion_id;
    }

    getPromotionName() {
        return this.promotion_name;
    }

    getLocationId() {
        return this.location_id;
    }

}
