import initializeGoogleAutocompleteAddressForm from './google-autocomplete-address-form';

document.addEventListener('DOMContentLoaded', function () {
    let addressForm = document.querySelector('[data-form-address-google-api-key]');

    if (null === addressForm || null === addressForm.getAttribute('data-form-address-google-api-key')) {
        return;
    }

    let script = document.createElement('script');
    script.src = "https://maps.googleapis.com/maps/api/js?libraries=places&key=" + addressForm.getAttribute('data-form-address-google-api-key');
    document.body.appendChild(script);

    script.addEventListener('load', function() {
        initializeGoogleAutocompleteAddressForm();
    });
});