export default class ClipboardCopyButton {
    constructor({selector = null, attr = null, message = 'success'}) {
        this.selector = selector;
        this.attr = attr;
        this.message = message;

        this.element = document.querySelector(this.selector);
        if (null !== this.element) {
            this.init();
        }
    }
    init() {
        this.element.addEventListener('click', () => {
            this.beforeClick();
        }, {passive: true});
    }
    beforeClick() {
        this.onClick();
    }
    onClick() {
        let textarea = document.createElement('textarea');
        textarea.value = this.element.getAttribute(this.attr);

        document.querySelector('body').appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.querySelector('body').removeChild(textarea);

        this.afterClick();
    }
    afterClick() {
        alert(this.message);
    }
}
