import sha256 from 'js-sha256'
import Fingerprint2 from 'fingerprintjs2'

let filterFingerprintComponents = function(only, components, withKeys) {
    if (only.length === 0) {
        return components
    }

    let filteredComponents = []
    for(let component of components) {
        if(-1 !== only.indexOf(component.key)) {
            filteredComponents.push(withKeys ? component : component.value)
        }
    }
    return filteredComponents
}

let getFingerprintComponentsHash = function(only, components) {
    return sha256(JSON.stringify(filterFingerprintComponents(only, components)));
}

let getFingerprint = function ({only = [], withKeys = true, hash = false}) {
    return new Promise(function(resolve, reject) {
        if (window.requestIdleCallback) {
            requestIdleCallback(function () {
                Fingerprint2.get(function (components) {
                    if (hash) {
                        resolve(getFingerprintComponentsHash(only, components))
                    }
                    resolve(filterFingerprintComponents(only, components, withKeys))
                })
            })
        } else {
            setTimeout(function () {
                Fingerprint2.get(function (components) {
                    if (hash) {
                        resolve(getFingerprintComponentsHash(only, components))
                    }
                    resolve(filterFingerprintComponents(only, components, withKeys))
                })
            }, 500)
        }
    })
}


export default getFingerprint;
