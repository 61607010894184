function initializeGoogleAutocompleteAddressForm() {
    var elementComponentTypeMapping = {
        'address_street': [
            'street_number',
            'route',
        ],
        'address_postalCode': [
            'postal_code',
        ],
        'address_city': [
            'locality',
        ],
        'address_country': [
            'country',
        ],
    };

    google.maps.event.addDomListener(window, 'load', function() {
        var element = document.querySelector('#address_street');
        var autocomplete = new google.maps.places.Autocomplete(element, { types: ['geocode'] });

        google.maps.event.addListener(autocomplete, 'place_changed', function () {
            for (let id in elementComponentTypeMapping) {
                let element = document.querySelector('#' + id);

                element.value = '';

                for (let componentType of elementComponentTypeMapping[id]) {
                    for (var component of this.getPlace().address_components) {

                        if (component.types.indexOf(componentType) > -1) {
                            var componentValue = element.localName === 'select' ? component.short_name : component.long_name;
                            element.value += element.value.length !== 0 ? (' ' + componentValue) : componentValue;
                        }
                    }
                }
            }
        });
    });
}

export default initializeGoogleAutocompleteAddressForm;