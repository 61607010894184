function initZoomableImage(element) {
    let container = element.parentElement;
    let scale = container.getAttribute('scale');

    container.style.overflow = 'hidden';

    element.addEventListener('mousemove', function (e) {
        let containerPosition = container.getBoundingClientRect();
        let p_x = (e.clientX - containerPosition.left) * 100 / containerPosition.width;
        let p_y = (e.clientY - containerPosition.top) * 100 / containerPosition.height;

        element.style.transformOrigin = p_x +"% " + p_y+"%";
    }, {passive: true});

    element.addEventListener('mouseenter', function (e) {
        if (window.innerWidth < 800) {
            return;
        }

        element.style.transform = scale ? 'scale('+scale+')' : 'scale(1.5)'
    }), {passive: true};

    element.addEventListener('mouseleave', function (e) {
        element.style.transform = 'none';
    }, {passive: true})
}

window.addEventListener('load', function () {
    let images = document.querySelectorAll('.image-hover-zoom img');

    for (let item of images) {
        initZoomableImage(item);
    }
});

var observer = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
        if (mutation.addedNodes.length >= 1) {
            for (var i = 0; i < mutation.addedNodes.length; i++) {
                if (undefined !== mutation.addedNodes[i].localName && null !== mutation.addedNodes[i].parentElement) {
                    if (null !== mutation.addedNodes[i].parentElement.querySelector('.image-hover-zoom img')) {
                        initZoomableImage(mutation.addedNodes[i].parentElement.querySelector('.image-hover-zoom img'));
                    } else if (mutation.addedNodes[i].parentElement.classList.contains('image-hover-zoom') && event.srcElement.localName === 'img') {
                        initZoomableImage(mutation.addedNodes[i]);
                    }
                }
            }
        }
    });
});

observer.observe(document.body, { attributes: false, childList: true, characterData: false, subtree: true });