export default class EnhancedEcommerceProduct {
    constructor({item_id = undefined, item_name = undefined, item_brand = undefined, item_categories = [], item_variant = undefined, item_list_name = undefined, price = 0, index = undefined, quantity = undefined}) {
        this.item_id = item_id;
        this.item_name = item_name;
        this.item_brand = item_brand;
        this.item_variant = item_variant;
        this.item_list_name = item_list_name;

        for (let i = 0; i < item_categories.length; i++) {
            this[0 === i ? 'item_category' : 'item_category'+i] = item_categories[i];
        }

        this.price = price.toFixed(2);
        this.index = index
        this.quantity = quantity;
    }

    getItemId() {
        return this.item_id;
    }

    getItemName() {
        return this.item_name;
    }

    getItemBrand() {
        return this.item_brand;
    }

    getItemVariant() {
        return this.item_variant;
    }

    getItemListName() {
        return this.item_list_name;
    }

    getPrice() {
        return this.price;
    }

    getIndex() {
        return this.index;
    }

    getQuantity() {
        return this.quantity;
    }
}
