import Loader from './Loader';

export default class AjaxForm {
    constructor({ selector = null, targetElements = null, isTriggerReplace = true, action = null, enabled = true, callback = function () { }, onError = function () { }, onSubmit = function () { } }) {
        this.selector = selector;
        this.targetElements = targetElements;
        this.isTriggerReplace = isTriggerReplace;
        this.action = action;
        this.callback = callback;
        this.onSubmit = onSubmit;
        this.onError = onError;
        this.enabled = enabled;
        this.loader = new Loader();

        this.element = document.querySelector(this.selector);
        if (null !== this.element) {
            this.init();
        }
    }
    enable() {
        this.enabled = true;
    }
    disable() {
        this.enabled = false;
    }
    init() {
        document.querySelectorAll(this.selector).forEach((form) => {
            if (form.classList.contains('ajax-form-initialized')) {
                return;
            }

            form.addEventListener('submit', (e) => {
                e.preventDefault();

                let promise = this.onSubmit.call(this);
                if (promise && typeof promise.then === 'function' && promise[Symbol.toStringTag] === 'Promise') {
                    promise.then((test) => {
                        this.submit(form);
                    })
                } else {
                    this.submit(form);
                }
            });

            form.classList.add('ajax-form-initialized');
        });
    }
    submit(form) {
        if (!this.enabled) {
            return;
        }

        this.loader.show();
        let headers = new Headers();
        headers.append('X-Requested-With', 'XMLHttpRequest');
        fetch(this.action || form.getAttribute('action'), {
            method: form.getAttribute('method'),
            body: new URLSearchParams(new FormData(form)),
            credentials: 'same-origin',
            headers: headers
        }).then((response) => {
            return response.text();
        }).then((html) => {
            let div = document.createElement('div');
            div.innerHTML = html;

            if (this.targetElements.length !== null) {
                Object.entries(this.targetElements).forEach(element => {
                    try {
                        let container = document.querySelector(element[0]);
                        let result = div.querySelector(element[1]);
                        container.innerHTML = result.innerHTML;

                        let firstScript = null;
                        let previousScript = null
                        result.querySelectorAll('script').forEach(function (resultScript) {
                            let script = document.createElement('script');

                            if (resultScript.src !== '') {
                                script.src = resultScript.src;
                            }

                            script.innerHTML = resultScript.innerHTML;

                            if (firstScript === null) {
                                firstScript = script;
                            }

                            if (previousScript !== null && previousScript.src !== '') {
                                previousScript.onload = function () {
                                    container.appendChild(script);
                                }
                            } else {
                                container.appendChild(script);
                            }

                            if (script.src !== '') {
                                previousScript = script;
                            }
                        })

                        if (null !== firstScript && firstScript.src !== '') {
                            container.appendChild(firstScript);
                        }
                    } catch (e) {
                        console.error(element[1].concat(' is not defined'));
                    }
                });
            }

            this.loader.hide();

            if (this.isTriggerReplace) {
                new AjaxForm({
                    selector: this.selector,
                    targetElements: this.targetElements,
                    isTriggerReplace: this.isTriggerReplace,
                    action: this.action,
                    callback: this.callback,
                    onSubmit: this.onSubmit,
                });
            }

            this.callback.call(this, div);
        }).catch(this.onError);
    }
}
